import { DataGridControl } from './DataGridControl.ts';
import { logger, $t } from 'o365-utils';
import { markRaw, h } from 'vue';

Object.defineProperties(DataGridControl.prototype, {
    'devTools': {
        get() {
            if (this._devTools == null) {
                this._devTools = new DataGridDevTools(this);
            }
            return this._devTools;
        }
    },
    'hasDevTools': {
        get() {
            return !!this._devTools;
        },
        set(_pValue) {
            // reactivity fix
        }
    }
});

/** Extension for adding devtools helpers to datagrids */
export default class DataGridDevTools {
    $: {
        dataGridControl: DataGridControl
    }

    infoColumnAdded = false;

    constructor(pDataGridControl: DataGridControl) {
        this.$ = markRaw({});
        this.$.dataGridControl = pDataGridControl;
    }

    /** Append debug info column in the grid */
    addDebugInfoColumn() {
        if (this.infoColumnAdded) { return; }
        this.infoColumnAdded = true;
        this.$.dataGridControl.asyncAddColumn({
            colId: 'o365_DevTools_Info',
            headerName: $t('DevTools: Info'),
            headerTextSlot: () => ' ', 
            pinned: 'left',
            suppressMovable: true,
            suppressNavigable: true,
            suppressSelection: true,
            disableMenu: true,
            disableResize: true,
            width: 50,
            cellRenderSlot: DevTooolsInfoColumnRenderer,
            boundFields: [],
        }, 2);
    }
}

function DevTooolsInfoColumnRenderer(props: any) {
    return h('button', {
        class: 'btn btn-sm btn-link py-0',
        onClick: () => {
            logger.info('Debug: ', props);
            debugger;
        }
    },
        h('i', {
            class: 'bi bi-info-circle'
        })
    );
}
DevTooolsInfoColumnRenderer.props = ['row', 'column']